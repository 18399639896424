<template>
  
  <v-container>
    <v-row 
      v-show="false" justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
      <v-col class="text-right" >
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on" 
        >
     
          Cadastrar Usuario
        </v-btn>
      </v-col>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Cadastro de Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="10"
              >
                <v-text-field
                  label="Nome completo"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
              
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >

              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="CPF"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Senha"
                  type="password"
                  
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  :items="['0-17', '18-29', '30-54', '54+']"
                  label="Age*"
                  required
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-autocomplete
                  :items="['Avaliador', 'Colaborador', 'Admin']"
                  label="Poderes"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table :loading="loading" :items="conta" :search="search" :headers="headers" :hide-default-footer="true">
          <template v-slot:top>
            <v-row>
              <v-col cols="11">
                <v-text-field v-model="search" label="Pesquisar" class="mx-4">
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn icon @click="get_usuario()"><v-icon>mdi-refresh</v-icon></v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.papeis`]="{item}">
            <span v-for="i in item.papel" :key="`papel-${i.id}`">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" outlined :color="i.cor" v-if="i.ativo" class="ma-2">
                    {{i.text}}
                    <v-icon>{{i.icon}}</v-icon>
                  </v-chip>
                </template>
                <span>{{i.text}}</span>
              </v-tooltip>
            </span>
          </template>

          <template v-slot:[`item.edit`]="{item}">
            <TrocaSenha :current-item="item" />

          </template>


          <template v-slot:[`item.config`]="{item}">
            <Edit :current-item="item" />
          </template>
       
        </v-data-table>
      </v-col>
      
    </v-row>
    
  </v-container>
  
</template>
<script>
import { mapGetters } from 'vuex'
import AppEtlService from '@/service/AppEtlService'

const api = new AppEtlService();
export default {
  name: 'Contas',
  components: {
    TrocaSenha: () => import("./TrocaSenha.vue"),
    Edit: () => import("./Edit.vue"),
    
},
  data: () => ({
    search: '',
    loading: false,
    dialog: false,
    headers: [
      { text: 'Mat', align: 'start', sortable: true, value: 'matricula', },
      { text: 'Nome', align: 'start', sortable: true, value: 'text', },
      { text: 'Usuário', align: 'start', sortable: true, value: 'cpf', },
      { text: 'Email', align: 'start', sortable: true, value: 'email', },
      { text: 'Ramal', align: 'start', sortable: true, value: 'ramal', },
      // { text: 'Papeis', align: 'end', sortable: true, value: 'papeis', },
      { text: 'Mudar Senha', align: 'end', sortable: true, value: 'edit', },
      { text: 'Editar usuario', align: 'end', sortable: true, value: 'config', },
    ],
  }),
  methods: {
    get_usuario() {
      this.loading = true
      api
        .get_api('contas/usuarios/')
        .then(response => { this.$store.dispatch('conta', response.data); })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading = false })
    },
    async usuario_permissoes() {
      this.loading = true
      return await api
        .get_api('contas/configs/')
        .then(response => { response.data  })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading = false })
    },
    async cadastrar_user(){
      this.loading=true
      return await api
        .get_api('/post_paciente_app/')
        .then(response => { console.log("AJASIU",response.data)  })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading = false })

    }
  }, computed: {
    ...mapGetters(['conta','permissoes'])
  }, mounted() {
    this.get_usuario()
    this.usuario_permissoes()
  }

}
</script>