import ApiService from './ApiService'

class AppEtlService extends ApiService {
    constructor() {
        super('/')
    }

    get_query(query, params = []) {
        let q = query > 0 ? `query=${query}` : `cod_query=${query}`
        return this.get(`etl/query/?${q}&params=${JSON.stringify(params)}`)
    }

    get_etl(url, params = '') {
        return this.get(`${url}/?${params}`)
    }

    get_api(url) {
        return this.get(`${url}`)
    }

    put_api(url, params) {
        return this.put(url, params)
    }

    create_etl(url, params) {
        return this.post(url, params)
    }

    put_etl(url, params) {
        return this.put(url, params)
    }

    delete_etl(url) {
        return this.delete(url)
    }

    get_procedimentos(pesquisa) {
        return this.get(`etl/api/v1/procedimentos/?${pesquisa}`)
    }

    create_agenda_paciente(obj) {
        return this.put('etl/api/v1/atendimentos/', obj)
    }

    create_paciente(obj) {
        return this.post(`etl/api/v1/pacientes/`, obj)
    }

    update_paciente(obj) {
        return this.put(`etl/query/?query=put_paciente_dp&params=${obj}/`)
    }

    get_agendamentos_livres_by_id_prestador(id_medico, mes) {
        return this.get(`etl/api/v1/agendacentral/livres/id_prestador?pesquisa=${id_medico}&mes=${mes}`)
    }

    get_medicos(pesquisa) {
        return this.get(`etl/api/v1/medicos?pesquisa=${pesquisa}`)
    }

    get_empresas(pesquisa) {
        return this.get(`etl/api/v1/empresas?pesquisa=${pesquisa}`)
    }

    get_convenios(pesquisa) {
        return this.get(`etl/api/v1/convenios?pesquisa=${pesquisa}`)
    }

    get_setores(pesquisa) {
        return this.get(`etl/api/v1/setores?pesquisa=${pesquisa}`)
    }

    get_agenda_central_by_id_paciente(pesquisa, datainicio, datafim) {
        return this.get(`etl/api/v1/agendacentral/id_paciente?pesquisa=${pesquisa}&datainicio=${datainicio}&datafim=${datafim}`)
    }

    get_agenda_central_by_id_prestador(pesquisa) {
        return this.get(`etl/api/v1/agendacentral/id_prestador?pesquisa=${pesquisa}`)
    }

    get_unidades(pesquisa) {
        return this.get(`etl/api/v1/unidades?pesquisa=${pesquisa}`)
    }

    get_tiposmarcacao() {
        return this.get(`etl/api/v1/tiposmarcacao/`)
    }



}

export default AppEtlService