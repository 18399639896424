import httpService from '@/http/index.js'


class ApiService {
    constructor(apiurl) {
        this.apiurl = apiurl;
    }

    getRequestUrl = (url) => `${this.apiurl}${url}`
    post = (url, objeto) => httpService.post(this.getRequestUrl(url), objeto)
    patch = (url, objeto) => httpService.patch(this.getRequestUrl(url), objeto)
    put = (url, objeto) => httpService.put(this.getRequestUrl(url), objeto)
    delete = (url) => httpService.delete(this.getRequestUrl(url))
    get = (url) => httpService.get(this.getRequestUrl(url))
}

export default ApiService